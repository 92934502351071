import React from "react"
import MachineLearningBlogImg from "../img/machine-lerning-interview.svg"
import MlInterviewImg from "../img/machine-learning.png"
import { Link } from "gatsby"
import NavigationBar from "../components/Navbar"
import MlRoll from "../components/MlRoll"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

const Scroll = loadable(() => import("../components/scroll"))
const SocialMedia = loadable(() => import("../components/social-media"));
const Footer3 = loadable(() => import("../components/footer3"))

export default class MachineLearningBlogPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Machine Learning Blogs</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            property="og:description"
            content="Learn machine learning and data science concepts to build industry projects and crack machine learning interviews."
          />
          <meta name="image" property="og:image" content={MlInterviewImg} />
          <meta
            name="keywords"
            content="ml interview preparation, machine learning applications, machine learinng concepts, ml concepts, machine learning interview questions, ml interview quesitons"
          />
          <meta
            name="title"
            property="og:title"
            content="Machine Learning Blogs"
          />
        </Helmet>
        <div className=" sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <div className="container mx-auto px-2 sm:pt-2 sm:px-3 lg:px-5 2xl:px-7">
            <div className="sm:grid sm:grid-cols-12 sm:gap-4">
              <div className="pt-4 sm:pt-6 lg:pt-8 xl:pt-10 mx-auto text-center sm:text-left sm:col-span-6">
                <h1 className="text-xl lg:text-2xl 2xl:text-3xl text-gray-900  tracking-wide">
                  <span className=""> Machine Learning Interview</span>
                </h1>
                <ul className="mt-2 tracking-wider sm:mt-3 text-gray-600 text-sm sm:text-xs md:text-sm lg:text-base 2xl:text-lg ">
                  <li className="ml-1 sm:ml-0">Learn fundamentals of machine learning</li>
                  <li className="mt-2 ml-1 sm:ml-0 sm:mt-3 md:mt-4">
                    Learn to build machine learning projects
                  </li>
                  <li className="mt-2 sm:mt-3 md:mt-4">
                    Learn critical guidance for ml interviews
                  </li>
                </ul>
              </div>

              <div className="mt-6 sm:mt-4 sm:col-span-6 flex justify-center">
                  <img
                    className="object-cover pointer-events-none w-80 h-44 sm:w-64 sm:h-36 md:w-72 md:h-40 lg:w-96 lg:h-56 2xl:w-112 2xl:h-64"
                    src={MachineLearningBlogImg}
                    alt="Machine learning blogs enjoyalgorithms"
                  />
                </div>
            </div>
            <div className="mt-6 px-1 md:px-3">
              <nav className="flex text-center  tracking-wider text-white text-sm sm:text-xs md:text-base xl:text-lg 2xl:text-xl">
                <Link
                  to="/coding-interview/"
                  className="z-30 flex-1 px-1 md:px-2 py-2 rounded shadow border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white transition duration-150"
                >
                  Coding Interview
                </Link>
                <Link
                  to="/machine-learning/"
                  className="z-30 flex-1 px-1 md:px-2 py-2 text-gray-900"
                >
                  Machine Learning
                </Link>
                <Link
                  to="/system-design/"
                  className="z-30 flex-1 px-1 pl-2 pr-2 md:px-2 py-2 rounded shadow border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white transition duration-150"
                >
                  System Design
                </Link>
                <Link
                  to="/oops-concepts/"
                  className="z-30 flex-1 px-1 md:px-2 py-2 rounded shadow border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white transition duration-150"
                >
                  OOPS Concepts
                </Link>
              </nav>
            </div>

            <div className="mt-6 xl:mt-8">
              <MlRoll />
            </div>

            <Scroll showBelow={250} />
            <SocialMedia />
            <Footer3 />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
